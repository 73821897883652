<template>
  <div class="pa-5">
    <base-material-card
      color="success"
      icon="mdi-clipboard-text"
      inline
      title="用户组管理"
      class="px-5 py-3 mb-5"
    >
      <v-row>
        <v-col>
          <v-row>
            <v-col md="3">
              <v-btn
                color="success"
                block
                @click="addUserGroupDialog = true"
              >
                <v-icon left>
                  fa fa-plus
                </v-icon>
                创建
              </v-btn>
            </v-col>
            <v-col md="3">
              <v-btn
                color="success"
                block
                disabled
              >
                <v-icon left>
                  fa fa-sign-in
                </v-icon>
                导入
              </v-btn>
            </v-col>
            <v-col md="3">
              <v-btn
                color="success"
                block
                disabled
              >
                <v-icon left>
                  fa fa-sign-out
                </v-icon>
                导出
              </v-btn>
            </v-col>
            <v-col md="12">
              <v-alert
                type="info"
                dense
                text
                outlined
                class="pa-0"
              >
                当前选择：{{ userGroup.name }}
              </v-alert>
              <v-text-field
                v-model="search"
                label="请输入用户组名称"
                outlined
                dense
                append-icon="fa fa-search"
              />
            </v-col>
          </v-row>
          <div class="tree_box">
            <v-treeview
              open-all
              rounded
              activatable
              selected-color="red"
              :items="userGroupTree"
              :search="search"
              @update:active="active"
            />
          </div>
        </v-col>
        <v-divider vertical />
        <v-col cols="7">
          <div>
            <v-tabs
              v-model="tab"
            >
              <v-tab>
                基本信息
              </v-tab>
              <v-tab>
                用户组权限
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item class="py-5">
                <v-autocomplete
                  v-model="userGroup.parentId"
                  label="父级"
                  placeholder="不选择父级，将新增为顶级节点"
                  :items="userGroups"
                  item-text="name"
                  item-value="id"
                  clearable
                  outlined
                  dense
                >
                  <template v-slot:item="{ item }">
                    <span
                      v-for="i in item.level"
                      :key="i"
                      class="ml-5"
                    />
                    <v-list-item-content>
                      <v-list-item-title v-html="item.name" />
                      <v-list-item-subtitle v-html="item.value" />
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-model="userGroup.name"
                  label="用户组名称"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="userGroup.value"
                  label="用户组编码"
                  placeholder="请填写英文名称或简写，不可与其他节点重复"
                  outlined
                  dense
                  value="ROLE_"
                />
                <v-text-field
                  v-model="userGroup.weight"
                  label="排序"
                  outlined
                  dense
                />
                <v-textarea
                  v-model="userGroup.note"
                  label="说明"
                  outlined
                  dense
                />
                <v-btn
                  color="success"
                  @click="editUserGroup(userGroup.id)"
                >
                  <v-icon left>
                    fa fa-save
                  </v-icon>
                  修改
                </v-btn>
                <v-btn
                  color="error"
                  class="ml-3"
                  @click="delUserGroup(userGroup.id, userGroup.name)"
                >
                  <v-icon left>
                    fa fa-trash-o
                  </v-icon>
                  删除
                </v-btn>
              </v-tab-item>
              <v-tab-item>
                <div class="mt-5">
                  <v-treeview
                    activatable
                    selectable
                    selected-color="red"
                    :items="items"
                  />
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-col>
      </v-row>
    </base-material-card>
    <v-dialog
      v-model="addUserGroupDialog"
      max-width="600px"
      class="dialog_box"
    >
      <v-card
        max-width="600"
      >
        <v-toolbar
          color="info"
          dark
        >
          <v-toolbar-title>创建用户组</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="addUserGroupDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4">
          <div class="form_box">
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-autocomplete
                    v-model="addUserGroupData.parentId"
                    label="父级"
                    placeholder="不选择父级，将新增为顶级节点"
                    :items="userGroups"
                    item-text="name"
                    item-value="id"
                    clearable
                    outlined
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <span
                        v-for="i in item.level"
                        :key="i"
                        class="ml-5"
                      />
                      <v-list-item-content>
                        <v-list-item-title v-html="item.name" />
                        <v-list-item-subtitle v-html="item.value" />
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="addUserGroupData.name"
                    label="用户组名称"
                    outlined
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="addUserGroupData.value"
                    label="用户组编码"
                    placeholder="请填写英文名称或简写，不可与其他节点重复"
                    outlined
                    dense
                    value="ROLE_"
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="addUserGroupData.weight"
                    label="排序"
                    placeholder="数值越大，排序靠后"
                    outlined
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-textarea
                    v-model="addUserGroupData.note"
                    label="说明"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="addUserGroup"
          >
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import jsonTree from '../../utils/tree'
  import { orderBy, cloneDeep } from 'lodash'
  export default {
    data () {
      return {
        addUserGroupDialog: false,
        tab: null,
        items: [
          {
            name: '1',
            id: '1',
            level: 1,
          },
          {
            name: '1-1',
            id: '11',
            level: 2,
          },
          {
            name: '1-1-1',
            id: '111',
            level: 3,
          },
        ],
        page: 1,
        actions: [
          {
            color: 'success',
            icon: 'mdi-pencil',
          },
          {
            color: 'error',
            icon: 'mdi-close',
          },
        ],
        search: '',
        userGroup: {
          parentId: '',
          name: '',
          value: '',
          weight: '',
          note: '',
        },
        addUserGroupData: {
          parentId: '',
          name: '',
          value: '',
          weight: '',
          note: '',
          type: 'team',
        },
        userGroups: [],
        userGroupTree: [],
      }
    },
    async created () {
      await this.getUserGroups()
    },
    methods: {
      async getUserGroups () {
        await this.$axios.get('/dictionaries/teams')
          .then(res => {
            const userGroups = res.data.data
            this.userGroups = orderBy(
              cloneDeep(userGroups),
              ['level', 'weight'],
              ['asc', 'asc'],
            )
            this.userGroupTree = jsonTree(userGroups, {
              id: 'id',
              pid: 'parentId',
              children: 'children',
            })
            console.log(this.userGroupTree)
          })
          .catch(err => {
            console.log(err)
          })
      },
      delUserGroup (id, name) {
        this.$swal({
          title: '你确定要删除"' + name + '"吗？',
          text: '删除后将无法恢复。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            this.$axios.delete(`/groups/${id}`)
              .then(res => {
                this.getUserGroups()
                this.$swal({
                  title: '删除成功',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
              .catch(err => {
                this.$swal({
                  title: '删除失败',
                  icon: 'error',
                  text: '原因:' + err,
                  confirmButtonText: '知道了',
                })
              })
          }
        })
      },
      editUserGroup (id) {
        this.$axios.put(`groups/${id}`, this.userGroup)
          .then(res => {
            this.$swal({
              title: '修改成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            this.getUserGroups()
          })
          .catch(err => {
            console.log(err)
          })
      },
      active (val) {
        console.log(val)
        if (val[0]) {
          const nowUserGroup = this.userGroups.filter(item => {
            return item.id === val[0]
          })
          this.userGroup = nowUserGroup[0]
          console.log(this.UserGroup)
        }
      },
      addUserGroup () {
        this.$axios.post('/groups', this.addUserGroupData)
          .then(res => {
            this.addUserGroupDialog = false
            this.$swal({
              title: '创建成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            this.getUserGroups()
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .main{
    margin-top: 0;
  }
  .form_box{
    max-height: 500px;
    overflow: auto;
  }
  .tree_box{
    max-height: 480px;
    overflow: auto;
  }
</style>
